import { useState, useRef, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle, Description } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import '../styles/styles.css'
import { NavBar } from "./NavBar";
import { initializeAOS } from "../integrations/aos-init";



const HeroSection = () => {
  const animatedTextRef = useRef(null);
  const [mostrarOferta, setmostrarOferta] = useState(false)
  useEffect(() => {
    initializeAOS(); // Inicializar AOS cuando el componente se monte

    setTimeout(() => {
      setmostrarOferta(true)
    }, "5500");
  }, []);

  /*useEffect={
    initParticles('particle-container', '#707070');
    const texts = [
      "Implementamos ERP",
      "Control de Asistencia con Geolocalización",
      "Trazabilidad de Vehículos",
      "Trazabilidad de Materiales",
      "Trazabilidad de Manteciones",
      "Trazabilidad de Mercaderías"
    ];
    let currentIndex = 0;

    const element = animatedTextRef.current;
    
     animateText();
    
  }, []);*/

  const navigation = [
    { name: 'Servicios', href: '#' },
    { name: 'Equipo', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Tips', href: '#' },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(true);

  return (
    <div className="heroContainer">
      <NavBar />
      {/* <BlackFridayBanner client:load /> */}
      <div className="heroBody">
        <div className="heroTexts" data-aos="fade-up">
          <h1 >ICOV TECHNOLOGIES</h1>
          <p className="heroTextsP">
            Transforma tu negocio con tecnología de vanguardia, <br />
            Desde soluciones ERP hasta trazabilidad en tiempo real, llevamos tu empresa al siguiente nivel.
          </p>
          <p className="heroTextsP">
            ¡Descubre cómo podemos ayudarte a mejorar tus procesos y aumentar tus ventas!
          </p>
          <div className="heroButtonsContainer">
            <a href="#contacto">
              <button className="buttonContacto">Contacto</button>
            </a>
            <a href="#servicios">
              <button className="buttonAnimado heroSectionInfo">
                <p>Más Información</p>
                <svg fill="#ffffff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon points="289.7,341.3 289.7,0 204.3,0 204.3,341.3 33.7,170.7 33.7,298.7 247,512 460.3,298.7 460.3,170.7 "></polygon> </g></svg>
              </button>
            </a>
          </div>
        </div>
        <div className="heroImages">
          <img src="/images/heroSection/chart.jpg" alt="" data-aos="fade-left" />
          <div style={{ display: "flex", gap: "15px" }} data-aos="fade-left" data-aos-delay="600" >
            <img src="/images/heroSection/consulting.jpg" alt="" />
            <img src="/images/heroSection/deal.jpg" alt="" />
          </div>
          <div style={{ display: "flex", gap: "15px" }} data-aos="fade-left" data-aos-delay="1200">
            <img src="/images/heroSection/ecommerce.jpg" alt="" />
            <img src="/images/heroSection/office.jpg" alt="" />
            <img src="/images/heroSection/smartphone.jpg" alt="" />
          </div>
        </div>
      </div>
      {/* <img src="/public/images/whatsapp.png" alt="Logo de Whatsapp" className="logoWhatsapp" onClick={() => window.open("https://api.whatsapp.com/send?phone=56929462056&text=Hola, quisiera realizar un consulta.", "_blank")} /> */}
      {mostrarOferta &&
        <div className="modalOfertaContainer" onClick={() => setmostrarOferta(false)}>
          <div className="modalOferta" data-aos="fade-up" data-aos-delay="1200">
            <img src="/images/ofertaNavidad.png" alt="" />
            <a href="https://calendly.com/felipe-ahumada-araya" target="_blank" rel="noopener noreferrer">
              <button ></button>
            </a>

            <h3>CLICK AQUÍ</h3>
            <img className="flecha" src="/images/arrow.png" alt="" />
          </div>
        </div>
      }
    </div>
  )
};

export default HeroSection;
