import { useEffect, useState } from 'react';
import '../styles/NavBar.css';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    };

    return (
        <nav className={`fixed top-0 w-full z-50 bg-gray-800 shadow-md transition-all duration-300 fondoFooter`}>
            <div className="container mx-auto flex items-center justify-between px-4 py-4">
                {/* Logo */}
                <a href="#home" className="flex items-center">
                    <img src="/images/icov-logo.png" alt="ICOV Logo" className="h-20" />
                </a>

                {/* Mobile Menu Toggle */}
                <button
                    className="lg:hidden text-white focus:outline-none"
                    aria-label="Toggle navigation"
                >
                    <span className="material-icons">menu</span>
                </button>

                {/* Navigation Links */}
                <div className="hidden lg:flex items-center space-x-6">
                    <a
                        href="#servicios"
                        className={`text-white hover:text-gray-300 ${activeLink === 'Servicios' ? 'font-bold border-b-2 border-white' : ''}`}
                        onClick={() => onUpdateActiveLink('Servicios')}
                    >
                        Servicios
                    </a>
                    <a
                        href="#Equipo"
                        className={`text-white hover:text-gray-300 ${activeLink === 'Equipo' ? 'font-bold border-b-2 border-white' : ''}`}
                        onClick={() => onUpdateActiveLink('Equipo')}
                    >
                        Equipo
                    </a>
                    <a
                        href="#FAQ"
                        className={`text-white hover:text-gray-300 ${activeLink === 'FAQ' ? 'font-bold border-b-2 border-white' : ''}`}
                        onClick={() => onUpdateActiveLink('FAQ')}
                    >
                        FAQ
                    </a>
                    <a
                        href="#Blog"
                        className={`text-white hover:text-gray-300 ${activeLink === 'Blog' ? 'font-bold border-b-2 border-white' : ''}`}
                        onClick={() => onUpdateActiveLink('Blog')}
                    >
                        Blog
                    </a>
                    <a
                        href="#Tips"
                        className={`text-white hover:text-gray-300 ${activeLink === 'Tips' ? 'font-bold border-b-2 border-white' : ''}`}
                        onClick={() => onUpdateActiveLink('Tips')}
                    >
                        Tips
                    </a>
                </div>

                {/* Social Icons */}
                <div className="hidden lg:flex items-center space-x-4">
                    <a href="https://www.instagram.com/icov.technologies/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/logos/instagram.png" alt="Instagram" className="h-6 w-6" />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61568517407724" target="_blank" rel="noopener noreferrer">
                        <img src="/images/logos/facebook.png" alt="Facebook" className="h-6 w-6" />
                    </a>
                    <a href="https://www.tiktok.com/@icov.technologies" target="_blank" rel="noopener noreferrer">
                        <img src="/images/logos/tiktok.png" alt="TikTok" className="h-6 w-6" />
                    </a>
                    <a href="https://www.youtube.com/@Icov-Technologies" target="_blank" rel="noopener noreferrer">
                        <img src="/images/logos/youtube.png" alt="YouTube" className="h-6 w-6" />
                    </a>
                </div>

                {/* Login Button 
                <div className="hidden lg:block">
                    <button className="text-white px-4 py-2" onClick={}>
                        Log in
                    </button>
                </div>*/}
            </div>
        </nav>
    );
};
